'use client'

import useStore from '@/utils/state'
import Storage from '@/utils/storage'
import { useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'

const storage = new Storage()
const onlyAnon = [
  'Login',
  'Register',
  'ResetPw',
  'VerifiedPw',
  'Home'
]

const withAuth = (Children) => {
  return props => {
    const [isAuth, setIsAuth] = useState(false)
    const [isProcessing, setIsProcessing] = useState(true)
    const [allow, setAllow] = useState(false)
    const router = useRouter()
    const token = useStore(state => state.token)

    useEffect(() => {
      if (token) {
        setIsAuth(true)
      } else {
        setIsAuth(false)
      }

      setIsProcessing(false)
    }, [setIsAuth, setIsProcessing, token])

    useEffect(() => {
      if (!isProcessing) {
        if (isAuth) {
          if (onlyAnon.includes(Children.displayName)) {
            router.replace('/dashboard')
            setAllow(false)
          } else {
            setAllow(true)
          }
        } else {
          if (onlyAnon.includes(Children.displayName)) {
            setAllow(true)
          } else {
            router.replace('/')
            setAllow(false)
          }
        }
      }
    }, [isAuth, isProcessing, setAllow, router])

    if (allow) {
      return <Children {...props} />
    }

    return <></>
  }
}

export default withAuth
