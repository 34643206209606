export default class Storage {
  Storage() { }

  get(key: string): any {
    return localStorage.getItem(key)
  }

  set(key: string, value: any): void {
    localStorage.setItem(key, value)
  }

  remove(key: string) {
    localStorage.removeItem(key)
  }
}
