'use client'

import withAuth from '@/hooks/withAuth'
import { UserPlus, UserCheck } from 'lucide-react'
import Link from 'next/link'

const Home = function () {
  return (
    <div className="container">
      <div className="hero">
        <div className="hero-content text-center">
          <div className="max-w-3xl">
            <h1 className="text-lg font-bold">
              CertifiedSpanish.com is your Spanish healthcare study guide and certification partner
            </h1>
            <p className="py-6 text-red-800">
              You may create a free account or login with an existing account
            </p>
          </div>
        </div>
      </div>
      <div className="columns-1 lg:columns-2 md:columns-2 px-8">
        <div className="w-full card bg-base-200 shadow-xl">
          <figure className="px-10 pt-10">
            <UserCheck size={60} />
          </figure>
          <div className="card-body items-center text-center">
            <h2 className="card-title">I have a</h2>
            <p>CertifiedSpanish Account</p>
            <div className="card-actions">
              <Link href="/user/login"><button className="btn btn-success text-white">Login</button></Link>
            </div>
          </div>
        </div>
        <div className="w-full card bg-base-200 shadow-xl mt-5 lg:mt-0 md:mt-0">
          <figure className="px-10 pt-10">
            <UserPlus size={60} />
          </figure>
          <div className="card-body items-center text-center">
            <h2 className="card-title">I need to create a</h2>
            <p>Free Account</p>
            <div className="card-actions">
              <Link href="/user/register"><button className="btn btn-neutral">Register</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Home.displayName = 'Home'

export default withAuth(Home)
