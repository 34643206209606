import { Category, ExamResults, SectionType } from '@/app/exams/schema';
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { Exam, Response } from '@/app/exams/schema'

export interface AppState {
  token: string | null;
  profile: any;
  currentExam: Exam | null;
  chosenCategories: Category[];
  examResults: ExamResults;
  camTestPassed: boolean | null;
  lastExamGroup: string | null;
  phaseOneCheck: boolean | undefined;
  phaseTwoCheck: boolean | undefined;
  voucher: string | null,
  setVoucher: (voucher: string) => void,
  setCamTestPassed: (passed: boolean) => void;
  setExamResults: (results: ExamResults) => void;
  setToken: (token: string) => void;
  setProfile: (profile: any) => void;
  setCurrentExam: (exam: Exam) => void;
  setChosenCategories: (categories: Category[]) => void;
  setExamResponse: (response: Response) => void;
  setExamGroup: (group: string) => void;
  setPhaseOneCheck: (phaseOneCheck: boolean) => void;
  setPhaseTwoCheck: (phaseTwoCheck: boolean) => void;
  reset: () => void
}

const useStore = create<AppState>()(
  devtools(
    persist(
      (set, get) => ({
        token: null,
        profile: null,
        currentExam: null,
        chosenCategories: [],
        examResults: null,
        camTestPassed: null,
        lastExamGroup: null,
        phaseOneCheck: undefined,
        phaseTwoCheck: undefined,
        voucher: null,
        setVoucher: (voucher: string) => set({ voucher }),
        setCamTestPassed: (passed: boolean) => set({ camTestPassed: passed }),
        setExamResults: (results: ExamResults) => set({ examResults: results }),
        setToken: (token: string) => set({ token }),
        setProfile: (profile: {}) => set({ profile }),
        setCurrentExam: (exam: Exam) => set({ currentExam: exam }),
        setChosenCategories: (categories: Category[]) => set({ chosenCategories: categories }),
        setExamResponse: (response: Response) => {
          const currentExam: Exam | null = get().currentExam

          currentExam?.responses.push(response)
          set({ currentExam: currentExam })
        },
        setExamGroup: (group: string) => set({ lastExamGroup: group }),
        setPhaseOneCheck: (phaseOneCheck: boolean) => set({ phaseOneCheck }),
        setPhaseTwoCheck: (phaseTwoCheck: boolean) => set({ phaseTwoCheck }),
        reset: () => set({ profile: null, token: null })
      }),
      {
        name: 'app'
      }
    )
  )
)

export default useStore
